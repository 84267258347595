import componentFactory from '@dovetail/core/core';
const exportableComponents = {
    /* PLOP_NEW_COMPONENT */
    TeamTapScreen: () => import(
    /* webpackChunkName: "IdeaPlaceScreen" */ './screens/IdeaPlaceScreen/IdeaPlaceScreen'),
    TeamTapStandalone: () => import(
    /* webpackChunkName: "TeamTapStandalone" */ './screens/TeamTapStandaloneScreen'),
};
export default class TeamTap {
    config;
    components;
    constructor(config) {
        this.config = config;
        this.config = config;
        this.components = Object.keys(exportableComponents).reduce((accumulator, componentName) => ({
            ...accumulator,
            [componentName]: componentFactory(exportableComponents[componentName], this.config),
        }), {});
    }
}
